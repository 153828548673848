
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Providers extends Vue {

    providers: OM.ProviderListInfoVM[] = [];

    created() {
        this.init();
    }

    init() {
        ProviderClient.getAllByCompany()
        .then(x => {
            this.providers = x;
        })
    }

    deleteProvider(providerIdentifier: string) {
        if(confirm("Do you really want to delete?")) {
            ProviderClient.deleteProvider(providerIdentifier)
            .then(x => {
                this.init();
            })
        }
    }

}
